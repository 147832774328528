jQuery(document).ready(function($) {

	// fade in content
	setTimeout(function () {
    	$('body').addClass('delayfade');
    }, 500);

    // padding-bottom fix for main element
    setTimeout(function () {
        $('main').attr('style', 'padding-bottom: ' + $('footer').outerHeight() + 'px;');
        $(window).trigger('resize'); // fix for parrallax overflow issue
    }, 500);

    $(window).on('resize', function() {
        $('main').attr('style', 'padding-bottom: ' + $('footer').outerHeight() + 'px;');
    });

    // Mobile Nav Toggle
	$('header #nav-toggle').click(function() {
        $(this).toggleClass('open');
        $('header .hrl-menu').toggleClass('open');
        $('body').toggleClass('nav-open');
    });
    // mobile submenu functionality 
    $('header li.menu-item-has-children').each(function() {
        $(this).append('<span class="subArrow"></span>');
    });
    $('header li.menu-item-has-children .subArrow, header li.menu-item-has-children.disable a').click(function(e) {
        $(this).parent().toggleClass('sub-open');
        $(this).parent().siblings().removeClass('sub-open');
    });

    
    // client login link fix
    $('.client-login-link a').attr('data-toggle', 'modal').attr('data-target', '.gm-client-login');
    $('.client-login-link a').click(function() {
        $('header #nav-toggle').removeClass('open');
        $('header .hrl-menu').removeClass('open');
        $('body').removeClass('nav-open');
    });


    // reset button for contact page form 
    if( document.getElementById('gform_1') ) {
        $('.gform_footer').append('<input type="reset" value="Reset" class="reset" />');
    }
    // addes button back after unsuccesful submission
    $(document).bind('gform_post_render', function(){
        if( document.getElementById('gform_1') ) {
            $('.gform_footer').append('<input type="reset" value="Reset" class="reset" />');
        }
    });


    // Search toggle
    $('.search-toggle').click(function(e) {
        e.preventDefault();
        $('.hrl-sform').toggleClass('open');

        if( $('.hrl-sform').hasClass('open') ) {
            $('.search-input').focus();
            document.getElementsByName('s')[0].placeholder='How can we help?';
        }
    });
    $('.search-input').blur(function() {
        $('.hrl-sform').removeClass('open');
        document.getElementsByName('s')[0].placeholder='Search';
        //document.getElementsByName('s')[0].value = ''; // clear input on blur?
    });
    $('.search-input').focus(function() {
        document.getElementsByName('s')[0].placeholder='How can we help?';
    });

    // disable links if disable class is present 
    $('.disable > a, a.disable').click(function(e) {
        e.preventDefault();
    });

    //fix for bootstrap accordion to add class to header 
    $('#accordion .panel-collapse').on('show.bs.collapse', function () {
        $(this).prev().addClass('open');
    });
    $('#accordion .panel-collapse').on('hide.bs.collapse', function () {
        $(this).prev().removeClass('open');
    });


    //**** PEOPLE / TEAM MEMBER SCRIPTS ****//
    
    // Affiliations sorting on People (team) Page
    $('#affiliations-filter .option').click(function(e) {
        e.preventDefault();
        
        // filtering classes functionality
        $(this).addClass('active');
        $(this).siblings().removeClass('active');
        
        // filtering system
        let affiliation = $(this).data('filter-option');        
        let teamMembers = $('.person');
        
        //console.log(teamMembers);
        teamMembers.each(function(index, item) {
            
            if( affiliation === 'all') {
                
                teamMembers.removeClass('hidden');
                
            } else if( $(item).data('affiliation') === affiliation ) {
                
                $(item).removeClass('hidden');
                
            } else {
                
                $(item).addClass('hidden'); 
                
            }
                       
        });       
    });

    $('.single-people .chinese-translation.toggle').click(function(e) {
        e.preventDefault();

        $('.single-people .main-content').toggleClass('ch-wrap');
        $(this).text( $(this).text() == '中文' ? '英 文' : '中文');

    });

    //*** END PEOPLE / TEAM MEMBER SCRIPTS ****//

    // BOOTSTRAP AFFIX INIT FOR BLOG CATEGORY MENU
    $('#cat-fixer').affix({
        offset: {
            top: 70,
            bottom: function () {
                return (this.bottom = $('footer').outerHeight(true) + $('.related-posts').outerHeight(true) + 80)
            }
        }
    });
    $(window).on('resize', function () {

        if( document.getElementById('cat-fixer') ) {
            $('#cat-fixer').data('bs.affix').options.offset.bottom = ()=> {
                return this.bottom = $('footer').outerHeight(true) + $('.related-posts').outerHeight(true) + 80;
            }
        }
        
    });


    // ** SLIDERS ** //

    // homepage slider
    $('#homepage-slider')
    .on('init', function() {
        $(".slider-arrows").appendTo("#homepage-slider");
    })
    .slick({
        autoplay: true,
        autoplaySpeed: 8000,
        dots: true,
        infinite: true,
        speed: 700,
        fade: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        cssEase: 'ease',
        lazyLoad: 'progressive',
        prevArrow: '<span class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
        nextArrow: '<span class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
        appendArrows: $("<div></div>").addClass("slider-arrows wow fadeInRight").attr("data-wow-offset", 100).insertAfter("#homepage-slider")
    });

    $('.people-slider')
    .on('init', function() {
        $(".slider-arrows").appendTo(".people-slider");
    })
    .slick({
        autoplay: false,
        autoplaySpeed: 8000,
        dots: true,
        infinite: true,
        speed: 700,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: '<span class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
        nextArrow: '<span class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',        
        appendArrows: $("<div></div>").addClass("slider-nav clearfix").insertAfter(".people-slider"),
        appendDots: $("<span></span>").addClass("slider-dots").appendTo(".slider-nav"),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    });

    $('.blog-people-slider')
    .on('init', function() {
        $(".slider-arrows").appendTo(".people-slider");
    })
    .slick({
        autoplay: true,
        autoplaySpeed: 8000,
        dots: true,
        infinite: true,
        speed: 700,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: '<span class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
        nextArrow: '<span class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',        
        appendArrows: $("<div></div>").addClass("slider-nav clearfix").insertAfter(".blog-people-slider"),
        appendDots: $("<span></span>").addClass("slider-dots").appendTo(".slider-nav"),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    });

    $('.testimonial-slider')
    .on('init', function() {
        $(".testimonial-slider-arrows").appendTo(".testimonial-slider");
    })
    .slick({
        autoplay: true,
        autoplaySpeed: 8000,
        dots: true,
        infinite: true,
        speed: 700,
        prevArrow: '<span class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
        nextArrow: '<span class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',        
        appendArrows: $("<div></div>").addClass("testimonial-slider-nav clearfix").insertAfter(".testimonial-slider"),
        appendDots: $("<span></span>").addClass("slider-dots").appendTo(".testimonial-slider-nav")
    });


    // simple animations with wow and animate.css
    new WOW().init();



    // CURATOR.IO - SOCIAL FEEDS

        // DEBUG & DEV HELP
        //Curator.debug = true;
        //console.log(Curator.Templates);
        //console.log(Curator);

    // customized ajax load more button
    Curator.Templates.custTemplateFeed = `
        <div class="crt-feed-window">
            <div class="crt-feed"></div>
        </div>
        <div class="crt-feed-more">
            <a href="#">Load more <i class="fa fa-caret-right" aria-hidden="true"></i></a>
        </div>`;

    // Custom Single Tweet Template 
    Curator.Templates.singleTemplate = `
        <div class="crt-post-v2 crt-post crt-post-<%=this.networkIcon()%> <%=this.contentTextClasses()%>  <%=this.contentImageClasses()%>" data-post="<%=id%>">
            <div class="crt-post-border">
                <div class="crt-post-c">

                    <div class="crt-post-content">
                        
                        <div class="curator-text crt-post-content-text">
                        
                            <%=this.parseText(text)%>

                            <div class="crt-post-header">
                                <span class="crt-social-icon"><i class="crt-icon-<%=this.networkIcon()%>"></i></span>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>`;

    //Custom Default Grid Template 
    Curator.Templates.gridTemplate = `
        <div class="crt-grid-post crt-grid-post-v2 crt-post-<%=id%> <%=this.contentImageClasses()%> <%=this.contentTextClasses()%> <%=network_name%>">
            <div class="crt-post-c">
                <div class="crt-post-content">
                    <div class="crt-hitarea" >

                        <div class="crt-post-normal">

                            <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="spacer" />

                            <div class="crt-post-content-image" style="background-image: url(<%=image%>);"></div>

                            <a href="javascript:;" class="crt-play"><i class="crt-play-icon"></i></a>

                            <span class="crt-social-icon crt-social-icon-normal"><i class="crt-icon-<%=this.networkIcon()%>"></i></span>

                        </div>

                        <div class="crt-post-hover">

                            <div class="innerwrap">

                                <div class="crt-post-content-text">
                                    <%=this.parseText(text)%>
                                </div>

                            </div>

                            <div class="social-icon">
                                <span class="crt-social-icon"><i class="crt-icon-<%=this.networkIcon()%>"></i></span>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>`;


    // Custom Single Tweet (used on home page)
    if (document.getElementById('curator-feed-single')){
        var singleWidget = new Curator.Panel({
            container: '#curator-feed-single',
            feedId: '67840D63-014A-4CEE-9BED-3386E978',
            postsPerPage: 1,
            panel: {
                autoPlay: false,
            },
            templatePost: 'singleTemplate',
        });
    }

    // Custom Default Grid (used in flexible content)
    if (document.getElementById('curator-feed-grid')){
        var gridWidget = new Curator.Grid({
            container: '#curator-feed-grid',
            feedId: $('#curator-feed-grid').data('feedId'),
            postsPerPage:12,
            grid: {
                minWidth: 300,
                rows: 4,
                showLoadMore: true
            },
            templateFeed: 'custTemplateFeed',
            templatePost: 'gridTemplate'
        });
    }

    // END CURATOR.IO SOCIAL FEEDS


    /**
    * Scroll Event Handler  -  used for header changes
    *
    */
    var header = $('header');
    var scrollSpy;

    scrollSpy = {
        lastScrollY: 0,
        ticking: false,

        /**
        * Callback for our scroll event - just
        * keeps track of the last scroll value
        */
        onScroll: function () {
            this.lastScrollY = window.scrollY;
            this.requestTick();
        },

        /**
        * Calls rAF if it's not already
        * been done already
        */
        requestTick: function () {
            if (!this.ticking) {
                requestAnimationFrame($.proxy(this.update, this));
                this.ticking = true;
            }
        },

        /**
        * Our animation callback
        */
        update: function () {
            if (this.lastScrollY > 100) {
                header.addClass('sticky');

                if ( this.lastScrollY > 200 ) {
                    header.addClass('down').removeClass('up');
                } else if (this.lastScrollY > 150) {
                    header.addClass('up').removeClass('down');
                }

            } else {
                header.removeClass('sticky up down');
            }

            // allow further rAFs to be called
            this.ticking = false;
        }
    };

    // only listen for scroll events
    window.addEventListener('scroll', $.proxy(scrollSpy.onScroll, scrollSpy), false);

    scrollSpy.onScroll();
    // End Scroll Event Handler



    // UNUSED BELOW

	// SmoovMoov Scroll Animation
	// $('.menu-item a, .logo1 a, .logo2 a, .smoovscroll').on('click', function(e) {
	// 	e.preventDefault();

	// 	var hash = this.hash,
 	//  	el = $(hash);

 	//    	$('html, body').animate({
	//     	scrollTop: el.offset().top - 50
	// 	}, 800);
	// });


	// device sniffer
	// if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
	// 	$( "video.main-video, .video-overlay" ).addClass( "mobileVideo" );
	// }
});
